<template>
    <div class="table-container" >
        <b-table
            :fields="tableFields"
            :items="budgetForm"
            responsive="true"
            bordered
            head-variant="light"
            sticky-header="true"
            no-border-collapse
        >
            <template #top-row="data">
                <td class="td-numbering text-center"></td>
                <td class="td-numbering text-center"></td>
                <td class="td-numbering text-center table-success">1</td>
                <td class="td-numbering text-center table-danger">2</td>
                <td class="td-numbering text-center table-info">3</td>
                <td class="td-numbering text-center table-primary">4</td>
                <td class="td-numbering text-center table-warning">5</td>
                <td class="td-numbering text-center table-success">6</td>
                <td class="td-numbering text-center"></td>
            </template>
            <template #head(selection)>
                <div class="text-center">
                    <b-form-checkbox
                        v-model="selectAll"
                        @change="onSelectAllChanged"
                        :unchecked-value="false" />
                </div>
            </template>
            <template #head(more)>
                <div class="text-center">
                    <i
                        :title="getCommonText('del_selected')" 
                        class="icon icon-close table-all-remove"
                        @click="$emit('deleteItem', `${selectAll ? getCommonText('all_cats') : getCommonText('selected_cats')}`)"/>
                </div>
            </template>
            <template #cell(selection)="data">
                <div class="text-center">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </div>
            </template>
            <template #cell(action)="data">
                <button
                    class="table-plus"
                    @click="$emit('setCurrCatName', data.item.good_type)"
                    :disabled="!data.item.categoryCode"
                >
                    <i class="icon icon-plus-circle"/>
                </button>
            </template>
            <template #cell(good_type)="data">
                <div class="column-width-100">
                    <multiselect
                            v-if="variantAttribute && data.item.newCat"
                            v-model="data.item.good_type"
                            label="name"
                            :placeholder="getFormText('select_cat')"
                            :options="dict"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            @input="$emit('changeValue', data.item, data.item.good_type, data)"
                        >
                    </multiselect>
                    <div v-else>{{ data.item.good_type.name }}</div>
                </div>
            </template>
            <template #cell(unit)="data">
                <div>{{ data.value }}</div>
            </template>
            <template #cell(amount)="data">
                <div class="text-right">{{ $n(data.value) }}</div>
            </template>
            <template #cell(price)="data">
                <div class="text-right">{{ $n(data.value) }}</div>
            </template>
            <template #cell()="data">
                <div class="text-right">{{ $n(data.value) }}</div>
            </template>
            <template #cell(files)="data">
                <span
                    class="blue pointer underline"
                    @click="$emit('onAllFilesClick', { ...data.item, name_ru: data.item.good_type.name_ru, name_kk: data.item.good_type.name_kk})"
                >({{data.item.files ? data.item.files.length : 0}})</span>
            </template>
            <template #cell(more)="data">
                <div class="text-center">
                    <i class="icon icon-clear table-remove" v-if="variantAttribute" :disabled="deletingCat" @click="$emit('deleteItem', `${getCommonText('curr_cat')}`, data.item, data.index, true)"></i>
                </div>
            </template>
            <template #bottom-row="data">
                <td class="text-right" colspan="6">{{ getCommonText('total') }}</td>
                <td class="text-right">{{ $n(total) }}</td>
                <td colspan="2"></td>
            </template>
        </b-table>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="$emit('addItem')"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'form-03-149-tab1',
    components: {
       
    },
    mixins: [],

    props: {
        budgetForm: {type: Array, required: true},
        deletingCat: {type: Boolean, required: true},
        dict: {type: Array, required: true},
        getCommonText: {type: Function, required: true},
        getUnitsText: {type: Function, required: true},
        isLoad: {type: Boolean, required: true},
        setFormText: {type: Function, required: true},
        variantAttribute: {required: true},
        total: {type: Number, required: true},
    },

    data() {
        return {
            selectAll: false
            };
    },
    mounted() {
    },

    watch: {},

    methods: {
        onSelectAllChanged(val) {
            this.$emit('setSelectAll', val)
        },

        setSelectAll(value) {
            this.selectAll = value;
        }
    },
    
    computed: {
        

        getFormText() {
            return this.setFormText('form_03_149.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'selection',
                        label: ' '
                    },
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'good_type',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'unit',
                        label: this.getUnitsText('measure')
                    },
                    {
                        key: 'amount',
                        label: this.getFormText('amount')
                    },
                    {
                        key: 'price',
                        label: this.getFormText('avg_single_cost')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('total_cost')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
}
</script>

<style scoped>
.table-plus {
    border: none;
}

</style>