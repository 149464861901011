<template>
    <div class="inner-container">
        <div class="section-title budget">
            <i class="icon icon-calculator-coins"></i>
            <span>Бюджет</span>
        </div>
        <div class="section-subtitle" v-show="formCodeURL === ''">
            <i class="icon icon-grid"></i><span>
                {{ $t("app.links.budget_requests") }}
            <budget-forms-list :curFormSelect="'/form01-123'"/></span>
        </div>

        <div 
            v-show="categoryMode" 
            class="filter-container filter-container_budget-forms"
        >
            <budget-header 
                ref="budgetHeader" 
                :form="form" 
                :yearReq="true" 
                :openDisabled="openDisabled"
                :currTabName="currTabName"
                @chgData="changeHeader" 
                @setProgress="setProgress"
            ></budget-header>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="bcHeader" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <Form03_149
            ref="curForm"
            v-if="formCodeURL==='03-149'"
            @setForm="setForm"
            @setOpenDisabled="setOpenDisabled"
            @setCategoryMode="setCategoryMode"
            @setProgress="setProgress"
            @setDataForBreadCrumbs="setDataForBreadCrumbs"
            @setCurrTabName="setCurrTabName"
        ></Form03_149>
        <Form02_149
            ref="curForm"
            v-if="formCodeURL==='02-149'"
            @setForm="setForm"
            @setOpenDisabled="setOpenDisabled"
            @setCategoryMode="setCategoryMode"
            @setProgress="setProgress"
            @setDataForBreadCrumbs="setDataForBreadCrumbs"
        ></Form02_149>
        <Form01_149
            ref="curForm"
            v-if="formCodeURL==='01-149'"
            @setForm="setForm"
            @setOpenDisabled="setOpenDisabled"
            @setCategoryMode="setCategoryMode"
            @setProgress="setProgress"
            @setDataForBreadCrumbs="setDataForBreadCrumbs"
        ></Form01_149>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new-1.vue';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';

import Form03_149 from "./Form03_149.vue";
import Form02_149 from "./Form02_149.vue";
import Form01_149 from "./Form01_149.vue";

export default {
    name: 'Forms',
    components: {   
                    BudgetFormsList,
                    BreadcrumbsFilter,
                    BudgetHeader,
                    Form03_149,
                    Form02_149,
                    Form01_149,
                },
    mixins: [],
    data() {
        return {
            form: {
                code: '',
                name_ru: '',
                name_kk: ''
            },
            formCodeURL: '',
            progress: 0,
            openDisabled: false,
            categoryMode: true,
            bcHeader: {},
            dataTypeFilter: null,
            variantName: null,
            currTabName: null,
            formsWithTabs: ['03-149']
        };
    },

    methods: {
        changeHeader(data) {
            this.formCodeURL = data.form.code;
            if (!this.formsWithTabs.includes(this.formCodeURL)) {
                this.setCurrTabName(null);
            }
            this.$nextTick(() => {
                this.$refs.curForm.changeHeader(data);

            })
        },

        setProgress(val) {
            this.progress = val;
        },

        setForm(form) {
            this.form = form;
        },

        setOpenDisabled(val) {
            this.openDisabled = val;
        },

        setCategoryMode(val) {
            this.categoryMode = val;
        },

        setDataForBreadCrumbs(header, curHeader) {
            const formName = curHeader.form;
            const spfName = curHeader.spf;
        // TODO Удалить formName, spfName, dataTypeFilter, variantName из миксинов когда все формы пенесены на этот формат
            this.bcHeader = {...header, formName: formName, spfName: spfName}
            this.dataTypeFilter = curHeader.dataType;
            this.variantName = curHeader.budgetVersion;
        },
        
        // TODO Удалить этот метод из миксинов когда все формы пенесены на этот формат
        openFilterByRef(refName) {
            this.$refs.budgetHeader.openFilterByRef(refName, this.bcHeader.mode);
        },

        setCurrTabName(val) {
            this.currTabName = val;
        }
        
    },
};
</script>

<style scoped>
    .filter-container_budget-forms {
        z-index: 1010;
        width: fit-content;
    }

</style>